<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.37662 10.0196C9.76715 10.4101 10.4003 10.4101 10.7908 10.0196C11.1814 9.62899 11.1814 8.99575 10.7908 8.60518L10.0128 7.82709L10.7908 7.049C11.1814 6.65843 11.1814 6.02519 10.7908 5.63462C10.4003 5.24405 9.76715 5.24405 9.37662 5.63462L7.89152 7.1199C7.50099 7.51047 7.50099 8.14371 7.89152 8.53428L9.37662 10.0196Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.6276 0.885865C5.62733 0.885865 5.62787 0.885865 5.6276 0.885865C5.62787 0.885865 5.62895 0.885865 5.62923 0.885865L12.3115 0.885864C13.684 0.885864 14.7966 1.99861 14.7966 3.37126L14.7966 12.2829C14.7966 13.6556 13.684 14.7683 12.3115 14.7683L3.40088 14.7683C2.02839 14.7683 0.915772 13.6556 0.915772 12.2829L0.915771 3.37126C0.915771 1.99861 2.02839 0.885865 3.40088 0.885865L5.6276 0.885865ZM12.7966 12.2829C12.7966 12.5509 12.5794 12.7681 12.3115 12.7681L6.62841 12.7681L6.62841 2.8861L12.3115 2.8861C12.5794 2.88609 12.7966 3.10331 12.7966 3.37126L12.7966 12.2829ZM4.62841 12.7681H3.40088C3.13296 12.7681 2.91577 12.5509 2.91577 12.2829L2.91577 3.37126C2.91577 3.10331 3.13296 2.8861 3.40088 2.8861H4.62841L4.62841 12.7681Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconExpandedLeft'
}
</script>
